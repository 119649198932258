import { canAccessRole } from "helpers/functionalHelper";
import useUserInfoQuery from "queries/useUserInfoQuery";
import { useEffect, useState } from "react";
import AppURLs from "routes/appUrls";

const RoleMiddleware = ({ children, roleAccess, history }) => {
  const [ready, setReady] = useState(false);
  const {
    userInfoQuery: { data: userInfo },
  } = useUserInfoQuery();

  useEffect(() => {
    if (userInfo && userInfo.id > 0) {
      if (canAccessRole(userInfo.roles, roleAccess)) {
        setReady(true);
      } else {
        history.replace(AppURLs.logout);
      }
    }
  }, [history, roleAccess, userInfo]);

  return (
    <>
      {ready > 0 ? (
        children
      ) : (
        <div className="page-content">
          <div className="container-fluid">Please Wait</div>
        </div>
      )}
    </>
  );
};

export default RoleMiddleware;
