export const AppQueryKey = {
  global: {
    appNotice: "app_notice",
  },
  auth: {
    forgetPassword: "forget_password",
  },
  user: {
    userInfo: "user_info",
  },
  management: {
    statusReport: "status_report",
    salaryTemplateUrl: "salary_template_url",
    paymentIssuingStatus: "payment_issuing_status",
    invoiceIssuingStatus: "invoice_issuing_status",
    withHoldingTaxReports: "withholding_tax_reports",
    cit: "fetch_cit",
    vat: "fetch_vat",
  },
  ttms: {
    fetchCompany: "fetch_company",
    companyInfo: "company_info",
    fetchStore: "fetch_store",
    storeInfo: "store_info",
    fetchRider: "fetch_rider",
    riderInfo: "rider_info",
    fetchRiderVat: "fetch_rider_vat",
    fetchDelivery: "fetch_delivery",
    deliveryInfo: "delivery_info",
    fetchInvoice: "fetch_invoice",
    fetchPublishInvoice: "fetch_publish_invoice",
    invoiceInfo: "invoice_info",
    fetchPayment: "fetch_payment",
    fetchPublishPayment: "fetch_publish_payment",
    paymentInfo: "payment_info",
    fetchMessage: "fetch_message",
  },
  logs: {
    publishLogs: "publish_logs",
    eventLogs: "event_logs",
  },
  settings: {
    getNotice: "get_notice",
    getNoticeList: "get_notice_list",
  },
};
