import { useLoading } from "pages/AppState";
import React, { useEffect, useState } from "react";
import SyncLoader from "react-spinners/SyncLoader";

const PageLoading = ({ isLoading, isTransparent }) => {
  const { isTransparent: globalTrans, loading } = useLoading();
  const [isLoad, setIsLoad] = useState(false);
  const [isTrans, setIsTrans] = useState(false);

  useEffect(() => {
    setIsLoad(isLoading || loading);
    setIsTrans(isTransparent || globalTrans);
  }, [isLoading, isTransparent, loading, globalTrans]);

  return (
    <>
      {isLoad && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 20000,
            display: "flex",
            backgroundColor: `rgba(255,255,255,${isTrans ? "0.5" : "1"})`,
          }}
        >
          <SyncLoader loading={isLoad} color="#6b63ff" />
        </div>
      )}
    </>
  );
};

export default PageLoading;
