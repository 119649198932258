import { USER_ROLES } from "common/constants/constants";
import React from "react";
import AppURLs from "./appUrls";

const ForgetPwd = React.lazy(() =>
  import("pages/Authentication/ForgetPassword")
);
const Login = React.lazy(() => import("pages/Authentication/Login.jsx"));
const Logout = React.lazy(() => import("pages/Authentication/Logout"));
const Register = React.lazy(() => import("pages/Authentication/Register"));
const Home = React.lazy(() => import("pages/Home/index"));
const UserProfile = React.lazy(() =>
  import("pages/Authentication/UserProfile")
);
const DownLoadS3 = React.lazy(() => import("pages/DownLoadS3/DownLoadS3.jsx"));
const NotAuthorized = React.lazy(() => import("pages/Errors/NotAuthorized"));
const Pages404 = React.lazy(() => import("pages/Errors/Page404"));
const TtmsUpdateCompany = React.lazy(() =>
  import("pages/TTMS/Company/TtmsUpdateCompany")
);
const TtmsCompany = React.lazy(() => import("pages/TTMS/Company/index"));
const TtmsDeliveryList = React.lazy(() => import("pages/TTMS/Delivery"));
const TtmsUploadDeliveryList = React.lazy(() =>
  import("pages/TTMS/Delivery/TtmsUploadDeliveryList")
);
const TtmsUpdateInvoice = React.lazy(() =>
  import("pages/TTMS/Invoice/TtmsUpdateInvoice")
);
const TtmsRider = React.lazy(() => import("pages/TTMS/Rider"));
const TtmsUploadRider = React.lazy(() =>
  import("pages/TTMS/Rider/TtmsUploadRider")
);
const TtmsUpdateRider = React.lazy(() =>
  import("pages/TTMS/Rider/TtmsUpdateRider")
);
const TtmsStore = React.lazy(() => import("pages/TTMS/Store"));
const TtmsRegisterStore = React.lazy(() => import("pages/TTMS/Store/TtmsRegisterStore"));
const TtmsUploadStore = React.lazy(() =>
  import("pages/TTMS/Store/TtmsUploadStore")
);
const TtmsUpdateStore = React.lazy(() =>
  import("pages/TTMS/Store/TtmsUpdateStore")
);
const TtmsEventLog = React.lazy(() => import("../pages/TTMS/EventLog/index"));
const TtmsInvoicePublished = React.lazy(() =>
  import("../pages/TTMS/Invoice/TtmsInvoicePublished")
);
const TtmsInvoice = React.lazy(() => import("../pages/TTMS/Invoice/index"));
const TtmsPaymentPublished = React.lazy(() =>
  import("../pages/TTMS/Payment/TtmsPaymentPublished")
);
const TtmsUpdatePayment = React.lazy(() =>
  import("../pages/TTMS/Payment/TtmsUpdatePayment")
);
const TtmsWithholdingTaxReports = React.lazy(() =>
  import("../pages/TTMS/TaxReports/TtmsWithholdingTaxReports")
);
const TtmsCitList = React.lazy(() =>
  import("../pages/TTMS/Cit/TtmsCitList")
);
const TtmsVatList = React.lazy(() =>
  import("../pages/TTMS/Vat/TtmsVatList")
);
const TtmsPayment = React.lazy(() => import("../pages/TTMS/Payment/index"));
const TtmsPublishLog = React.lazy(() =>
  import("../pages/TTMS/PublishLog/index")
);
const TTMSDownloadForStore = React.lazy(() =>
  import("../pages/Public/TTMSDownloadForStore")
);
const TTMSDownloadInvoice = React.lazy(() =>
  import("../pages/Public/TTMSDownloadInvoice")
);
const DownloadLink = React.lazy(() =>
  import("pages/TTMS/DownloadLink/index")
);
const Message = React.lazy(() => import("pages/TTMS/Message/index"));

/**
 * Pages for public/anonymous
 */
const publicRoutes = [
  {
    path: AppURLs.public.ttmsDownloadForStore,
    component: TTMSDownloadForStore,
  },
  {
    path: AppURLs.public.ttmsDownloadInvoice,
    component: TTMSDownloadInvoice,
  },
];

/**
 * Pages for authentication
 */
const authRoutes = [
  {
    path: AppURLs.logout,
    component: Logout,
  },
  {
    path: AppURLs.login,
    component: Login,
  },
  {
    path: AppURLs.forgotPassword,
    component: ForgetPwd,
  },
  {
    path: AppURLs.register,
    component: Register,
  },
];

/**
 * Common pages
 */
const commonRoutes = [
  // Common
  {
    path: AppURLs.common.profile,
    component: UserProfile,
    roleAccess: [
      USER_ROLES.SUPPER_ADMIN,
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.NORMAL_USER,
    ],
  },
  /*   {
    path: "/sendSMS",
    component: SendSMS,
    roleAccess: [USER_ROLES.SUPPER_ADMIN],
  },
  {
    path: "/sendalimtalk",
    component: SendAlimtalk,
    roleAccess: [USER_ROLES.SUPPER_ADMIN],
  }, */
  {
    path: AppURLs.common.downloadS3,
    component: DownLoadS3,
    roleAccess: [USER_ROLES.SUPPER_ADMIN],
  },
  {
    path: "/",
    component: Home,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
];

const errorRoutes = [
  {
    path: AppURLs.error.authFailed,
    component: NotAuthorized,
  },
  {
    path: AppURLs.error.authFailed,
    component: Pages404,
  },
];

const ttmsRoutes = [
  /* TTMS - data management */
  // 본사-지사 관리
  {
    path: AppURLs.ttms.company,
    component: TtmsCompany,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.companyUpdate,
    component: TtmsUpdateCompany,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  // 가맹점 관리
  {
    path: AppURLs.ttms.store,
    component: TtmsStore,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.storeRegister,
    component: TtmsRegisterStore,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.storeUpload,
    component: TtmsUploadStore,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.storeUpdate,
    component: TtmsUpdateStore,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  // 기사 관리
  {
    path: AppURLs.ttms.rider,
    component: TtmsRider,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.riderUpload,
    component: TtmsUploadRider,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.riderUpdate,
    component: TtmsUpdateRider,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  // 배달내역 관리
  {
    path: AppURLs.ttms.deliveryList,
    component: TtmsDeliveryList,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.deliveryListUpload,
    component: TtmsUploadDeliveryList,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  /* TTMS - publish */
  {
    path: AppURLs.ttms.invoice,
    component: TtmsInvoice,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.invoicePublished,
    component: TtmsInvoicePublished,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.invoiceUpdate,
    component: TtmsUpdateInvoice,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.payment,
    component: TtmsPayment,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.paymentPublished,
    component: TtmsPaymentPublished,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.paymentUpdate,
    component: TtmsUpdatePayment,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.withHoldingTaxReports,
    component: TtmsWithholdingTaxReports,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.cit,
    component: TtmsCitList,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.vat,
    component: TtmsVatList,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  /* TTMS - Etc */
  {
    path: AppURLs.ttms.logsPublish,
    component: TtmsPublishLog,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.logsEvent,
    component: TtmsEventLog,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.downloadLink,
    component: DownloadLink,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
  {
    path: AppURLs.ttms.message,
    component: Message,
    roleAccess: [
      USER_ROLES.PARTNER_COMPANY,
      USER_ROLES.SUPPER_ADMIN,
    ],
  },
];

export {
  authRoutes, commonRoutes,
  errorRoutes, publicRoutes, ttmsRoutes
};

