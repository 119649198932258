import user1 from "assets/images/users/avatar.jpg";
import useUserInfoQuery from "queries/useUserInfoQuery";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import AppURLs from "routes/appUrls";

const ProfileMenu = props => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { t } = useTranslation();
  const {
    userInfoQuery: { data: profileData },
  } = useUserInfoQuery();

  const toggle = useCallback(() => {
    setIsMenuOpen(!isMenuOpen);
  }, [isMenuOpen, setIsMenuOpen]);

  return (
    <React.Fragment>
      <Dropdown isOpen={isMenuOpen} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-2">
            {profileData?.name || "No name"}&nbsp;
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          {/* Profile */}
          <DropdownItem tag="a" href={AppURLs.common.profile}>
            <i className="bx bx-user font-size-16 align-middle ms-1 me-3" />
            {t("사용자 정보")}
          </DropdownItem>

          <div className="dropdown-divider" />

          {/* Logout */}
          <Link to={AppURLs.logout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-3 text-danger" />
            <span>{t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
