const AppURLs = {
  // public pages
  public: {
    ttmsDownloadForStore: "/store/download/:period/:partner_id/:token",
    ttmsDownloadInvoice: "/invoice/download/:period/:partner_id/:token",
  },

  // auth pages
  root: "/",
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  register: "/register",

  // common pages
  common: {
    root: "/",
    profile: "/profile",
    downloadS3: "/download-s3",
  },

  // error pages
  error: {
    authFailed: "/authfailed",
    page404: "/*",
  },

  // menu pages
  ttms: {
    invoice: "/ttms/invoice",
    invoicePublished: "/ttms/invoice/published",
    invoiceUpdate: "/ttms/invoice/update",
    payment: "/ttms/payment",
    paymentPublished: "/ttms/payment/published",
    paymentUpdate: "/ttms/payment/update",
    withHoldingTaxReports: "/ttms/taxreports/withholdingtaxreports",
    cit: "/ttms/cit",
    vat: "/ttms/vat",
    company: "/ttms/company",
    companyUpdate: "/ttms/company/update",
    store: "/ttms/store",
    storeRegister: "/ttms/store/register",
    storeUpload: "/ttms/store/upload",
    storeUpdate: "/ttms/store/update",
    rider: "/ttms/rider",
    riderUpload: "/ttms/rider/upload",
    riderUpdate: "/ttms/rider/update",
    deliveryList: "/ttms/delivery-list",
    deliveryListUpload: "/ttms/delivery-list/upload",
    logsPublish: "/ttms/logs/publish",
    logsEvent: "/ttms/logs/event",
    downloadLink: "/ttms/download-link",
    message: "/ttms/message",
  },
};

export default AppURLs;
