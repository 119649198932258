import { getAsync, postAsync, putAsync } from "helpers/apiHelper";

export const LoginApiService = {
  loginUser: data => {
    return postAsync(`/partner-admin/login`, {
      username: data.email,
      password: data.password,
    });
  },
  checkOTP: data => {
    return postAsync(`/admin/loginotpauth`, data);
  },
  changeUserData: data => {
    return putAsync(`/partner-admin`, {
      name: data.name,
      //mobile_no: data.mobile_no,    // mobile number is not editable
    });
  },
  changePassword: data => {
    return putAsync("/partner-admin", {
      password: data.password,
    });
  },
  refreshTokenAsync: () => {
    return getAsync("/admin/refreshtoken/", {});
  },
};
