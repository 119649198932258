import useToken from "pages/Hooks/useToken";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import AppURLs from "routes/appUrls";
import Rolemiddleware from "./Rolemiddleware";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roleAccess,
  userData,
  ...rest
}) => {
  const { getAccessToken } = useToken();

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !getAccessToken()) {
          let targetUrl = AppURLs.error.authFailed;
          if (props.location.pathname === "/") {
            // 루트 경로로 들어온 요청이 인증 실패인 경우 login 화면으로 이동
            targetUrl = AppURLs.login;
          }
          return (
            <Redirect
              to={{ pathname: targetUrl, state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            {isAuthProtected ? (
              <Rolemiddleware history={props.history} roleAccess={roleAccess}>
                <Component {...props} />
              </Rolemiddleware>
            ) : (
              <Component {...props} />
            )}
          </Layout>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
