/* eslint-disable no-dupe-class-members */
import { hideRightSidebar, toggleRightSidebar } from "helpers/uiHelper";
import React, { Component } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n";
import RightSidebar from "../CommonForBoth/RightSidebar";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./Navbar";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
    this.toggleRightSidebar = toggleRightSidebar;
    this.hideRightBar = hideRightSidebar;
  }

  /**
   * Open/close right sidebar
   */
  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  componentDidMount() {
    const title = this.props.location.pathname;
    let currentPage = title.charAt(1).toUpperCase() + title.slice(2);

    document.title = currentPage + " | Skote - React Admin & Dashboard Template";
  }
  [this.props.location.pathname];

  componentDidMount() {
    document.body.addEventListener("click", this.hideRightBar, true);

    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
      document.getElementById("status").style.display = "none";
    }

    window.scrollTo(0, 0);

    // const title = this.props.location.pathname
    // let currentage = title.charAt(1).toUpperCase() + title.slice(2)

    // document.title =
    //   currentage + " | Skote - React Admin & Dashboard Template"

    this.props.changeLayout("horizontal");
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }
    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }
    if (this.props.layoutModeType) {
      this.props.changeLayoutMode(this.props.layoutModeType);
    }
  }

  /**
   * Opens the menu - mobile
   */
  openMenu = e => {
    this.setState({ isMenuOpened: !this.state.isMenuOpened });
  };

  //hides right sidebar on body click
  hideRightBar = event => {
    var rightBar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightBar && rightBar.contains(event.target)) {
      return;
    } else {
      if (document.body.classList.contains("right-bar-enabled")) {
        this.props.toggleRightSidebar(false);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <I18nextProvider i18n={i18n}>
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>
          <div id="layout-wrapper">
            <Header
              theme={this.props.topbarTheme}
              isMenuOpened={this.state.isMenuOpened}
              toggleRightSidebar={this.toggleRightSidebar}
              openLeftMenuCallBack={this.openMenu}
            />
            <Navbar menuOpen={this.state.isMenuOpened} />
            <div className="main-content">{this.props.children}</div>
            <Footer />
          </div>
          {this.props.showRightSidebar ? <RightSidebar /> : null}
        </I18nextProvider>
      </React.Fragment>
    );
  }
}

export default Layout;
