export const ScreenType = {
  COMPANY: 1,
  INVOICE: 2,
  PAYMENT: 3,
  INSURANCE: 4,
  INSURANCE_CHANGE: 5,
  STORE: 11,
  RIDER: 21,
  DELIVERY_LIST: 31,
}

export const UploadType = {
  NONE: -100,
  COMPANY: 0,
  COMPANY_BRANCH: 1,
  INVOICE: 2,
  PAYMENT: 3,
  INSURANCE: 4,
  INSURANCE_CHANGE: 5,
  STORE: 11,
  RIDER: 21,
  DELIVERY_LIST: 31,
  AUTH_FILE: 101,
}

export const API_RESULT = {
  Success: "success",
  Failed: "failure",
}

// TODO: 권한 정리
export const USER_ROLES = {
  NOT_IDENTIFIED: 0,
  SUPPER_ADMIN: 1,
  PARTNER_COMPANY: 2,
  NORMAL_USER: 4
}

export const AUTH_TYPE = {
  NONE: "NONE",
  SECURITY_CARD: "SECURITY_CARD",
  CERT_PFX: "CERT_PFX",
  CERT_DER_KEY: "CERT_DER_KEY",
}

export const CERT_FILE_EXTENSION = {
  PFX: ".pfx",
  DER: ".der",
  KEY: ".key",
  JPG: ".jpg",
  JPEG: ".jpeg",
  PNG: ".png",
}

export const PROCESS_TYPE = {
  책임배송: "책임배송",
  알선중개: "알선중개",
}

export const WithholdingTaxReportType = {
  매월: "매월",
  반기: "반기",
  수정: "수정",
  연말: "연말",
  소득처분: "소득처분",
  환급신청: "환급신청",
}

export const DEFAULT_LIST_PAGE_SIZE = 10
