import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 5 * (60 * 1000),
      cacheTime: 10 * (60 * 1000),
      retry: 2,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.Fragment>
);
serviceWorker.unregister();
