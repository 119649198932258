import React from "react";
import { useModalStore } from "pages/AppState";
import useGlobalModal from "pages/Hooks/useGlobalModal";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "./AppModal.scss";

const AppModal = () => {
  const { closeModal } = useGlobalModal();
  const header = useModalStore(state => state.header);
  const onConfirm = useModalStore(state => state.onConfirm);
  const content = useModalStore(state => state.content);
  const isOpen = useModalStore(state => state.isOpen);

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={closeModal}
      className="app-modal"
      container={document.getElementById("mobile-landing-container")}
    >
      {header && <ModalHeader tag={"div"}>{header}</ModalHeader>}
      <ModalBody>{content}</ModalBody>
      <ModalFooter
        className="hover fw-bold"
        onClick={() => {
          closeModal();
          onConfirm?.();
        }}
      >
        확인
      </ModalFooter>
    </Modal>
  );
};

export default AppModal;
