import { toast } from "react-toastify";
import { logger } from "./debug/logger";

/**
 * @deprecated
 *
 * @param {*} url
 * @param {*} forceDownLoad
 * @returns
 */
export const getPreSignedUrlS3FromUrl = async (url, forceDownLoad) => {
  /* if (!url) return "";
  try {
    const { region, bucket, key } = AmazonS3URI(url);
    const commandInput = {
      Bucket: bucket,
      Key: key,
      ResponseContentType: "binary/octet-stream",
    };
    if (forceDownLoad) {
      commandInput.ResponseContentType = "binary/octet-stream";
    }
    const command = new GetObjectCommand(commandInput);
    const client = new S3Client({
      region: region,
      credentials: {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
      },
    });
    const result = await getSignedUrl(client, command);
    return result;
  } catch (e) {
    logger(e);
    return "";
  } */
  return url;
};

export const downloadFile = async (fileUrl, fileName) => {
  const ancher = document.createElement("a");
  ancher.href = fileUrl;
  if (fileName) ancher.download = fileName;
  ancher.style.display = "none";
  document.body.appendChild(ancher);
  ancher.click();
  document.body.removeChild(ancher);
};

export const getFileNameFromUrl = url => {
  if (!url) return "";
  const cropped = url.split("/").pop();
  // convert url encoded string to normal string
  return decodeURIComponent(cropped);
};

export const canAccessRole = (input = [], roles) => {
  if (!roles) {
    return true;
  }
  return input.some(item => roles.includes(item));
};

export const delay = (time = 1000) =>
  new Promise(resolve => setTimeout(resolve, time));

export const handleCopy = text => {
  if (!text) return;
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    fallbackCopyTextToClipboard(text);
  }
  toast.dismiss();
  toast.info("Copied: " + text);
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    logger("Fallback: Copying text command was " + msg);
  } catch (err) {
    logger("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

/**
 * @deprecated
 *
 * @param {*} plainText
 * @returns
 */
export const AESEncryption = (plainText = "") => {
  /* if (!plainText) {
    return "";
  }
  let input = plainText;
  while (input.length % 16 !== 0) {
    input += " ";
  }
  const b64 = CryptoJS.AES.encrypt(
    input,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET),
    {
      iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding,
    }
  ).toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  const eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex; */
  return plainText;
};

/**
 * @deprecated
 *
 * @param {*} cipherText
 * @returns
 */
export const AESDecryption = cipherText => {
  /* const reb64 = CryptoJS.enc.Hex.parse(cipherText);
  const bytes = reb64.toString(CryptoJS.enc.Base64);
  const decrypt = CryptoJS.AES.decrypt(
    bytes,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET),
    {
      iv: CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_SECRET),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.NoPadding,
    }
  );
  const plain = decrypt.toString(CryptoJS.enc.Utf8);
  return plain.trim(); */
  return cipherText;
};
