import HorizontalLine from "components/Custom/HorizontalLine";
import MetisMenu from "metismenujs";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import AppURLs from "routes/appUrls";
import SimpleBar from "simplebar-react";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }


  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li>
                <Link to="/#" className="">
                  <i className="bx bx-home-circle" />
                  <span>{this.props.t("Home")}</span>
                </Link>
              </li>

              <HorizontalLine
                width="100%"
                border="5"
                color="e7e7e7"
                marginTop="0px"
                marginBottom="5px"
              />

              {/* Label: 총판 관리 */}
              <li className="menu-title">{this.props.t("총판 관리")}</li>

              {/* 본사/지사 등록 */}
              <li>
                <Link to={AppURLs.ttms.companyUpdate} className="">
                  <i className="bx bx-plus" />
                  <span>{this.props.t("본사·지사 등록")}</span>
                </Link>
              </li>

              {/* 본사/지사 관리 */}
              <li>
                <Link to={AppURLs.ttms.company} className="">
                  <i className="bx bx-building-house" />
                  <span>{this.props.t("본사·지사 관리")}</span>
                </Link>
              </li>

              <HorizontalLine
                width="100%"
                border="5"
                color="e7e7e7"
                marginTop="0px"
                marginBottom="5px"
              />

              {/* Label: 데이터 관리 */}
              <li className="menu-title">{this.props.t("데이터 관리")}</li>

              {/* 가맹점 파일 등록 */}
              <li>
                <Link to={AppURLs.ttms.storeUpload} className="">
                  <i className="bx bx-list-plus" />
                  <span>{this.props.t("가맹점 파일 등록")}</span>
                </Link>
              </li>

              {/* 가맹점 등록 */}
              <li>
                <Link to={AppURLs.ttms.storeRegister} className="">
                  <i className="bx bx-plus" />
                  <span>{this.props.t("가맹점 등록")}</span>
                </Link>
              </li>

              {/* 가맹점 관리 */}
              <li>
                <Link to={AppURLs.ttms.store} className="">
                  <i className="bx bx-store" />
                  <span>{this.props.t("가맹점 관리")}</span>
                </Link>
              </li>

              {/* 가맹점별 부가세 */}
              {/* <li>
                <Link to={AppURLs.ttms.storeVAT} className="">
                  <i className="bx bx-receipt" />
                  <span>{this.props.t("가맹점별 부가세")}</span>
                </Link>
              </li> */}

              <HorizontalLine width="80%" marginTop="0px" marginBottom="5px" />

              {/* 기사 등록 */}
              <li>
                <Link to={AppURLs.ttms.riderUpload} className="">
                  <i className="bx bx-list-plus" />
                  <span>{this.props.t("기사 파일 등록")}</span>
                </Link>
              </li>

              {/* 기사 관리 */}
              <li>
                <Link to={AppURLs.ttms.rider} className="">
                  <i className="mdi mdi-motorbike" />
                  <span>{this.props.t("기사 관리")}</span>
                </Link>
              </li>

              <HorizontalLine width="80%" marginTop="0px" marginBottom="5px" />

              {/* 배달내역 등록 */}
              <li>
                <Link to={AppURLs.ttms.deliveryListUpload} className="">
                  <i className="bx bx-list-plus" />
                  <span>{this.props.t("배달 내역 등록")}</span>
                </Link>
              </li>

              {/* 배달내역 */}
              <li>
                <Link to={AppURLs.ttms.deliveryList} className="">
                  <i className="bx bx-food-menu" />
                  <span>{this.props.t("배달 내역")}</span>
                </Link>
              </li>

              <HorizontalLine
                width="100%"
                border="5"
                color="e7e7e7"
                marginTop="0px"
                marginBottom="5px"
              />

              {/* Label: 본사세무관리 */}
              <li className="menu-title">{this.props.t("세무관리")}</li>

              {/* 세금계산서 발행 */}
              <li>
                <Link to={AppURLs.ttms.invoice} className="">
                  <i className="bx bx-printer" />
                  <span>{this.props.t("전자세금계산서 발행")}</span>
                </Link>
              </li>

              {/* 간이지급명세서 제출 */}
              <li>
                <Link to={AppURLs.ttms.payment} className="">
                  <i className="bx bx-receipt" />
                  <span>{this.props.t("간이지급명세서 제출")}</span>
                </Link>
              </li>

              <HorizontalLine width="80%" marginTop="0px" marginBottom="5px" />

              {/* 세금계산서 발행결과 */}
              <li>
                <Link to={AppURLs.ttms.invoicePublished} className="">
                  <i className="bx bx-printer" />
                  <span>{this.props.t("전자세금계산서 발행결과")}</span>
                </Link>
              </li>

              {/* 간이지급명세서 제출결과 */}
              <li>
                <Link to={AppURLs.ttms.paymentPublished} className="">
                  <i className="bx bx-receipt" />
                  <span>{this.props.t("간이지급명세서 제출결과")}</span>
                </Link>
              </li>

              <HorizontalLine width="80%" marginTop="0px" marginBottom="5px" />

              {/* 원천세 신고내역 */}
              <li>
                <Link to={AppURLs.ttms.withHoldingTaxReports} className="">
                  <i className="bx bxs-report" />
                  <span>{this.props.t("원천세 신고")}</span>
                </Link>
              </li>

              {/* 종소세 신고내역 */}
              <li>
                <Link to={AppURLs.ttms.cit} className="">
                  <i className="bx bx-list-ul" />
                  <span>{this.props.t("종소세 신고내역")}</span>
                </Link>
              </li>

              {/* 부가세 신고내역 */}
              <li>
                <Link to={AppURLs.ttms.vat} className="">
                  <i className="bx bx-list-ul" />
                  <span>{this.props.t("부가세 신고내역")}</span>
                </Link>
              </li>

              {/* 발행·신고 이력 */}
              {/* <li>
                <Link to={AppURLs.ttms.logsPublish} className="">
                  <i className="bx bx-list-ul" />
                  <span>{this.props.t("발행·신고 이력")}</span>
                </Link>
              </li> */}

              {/* 수정로그 조회 */}
              {/* <li>
                <Link to={AppURLs.ttms.logsEvent} className="">
                  <i className="bx bx-list-ul" />
                  <span>{this.props.t("수정·삭제 내역")}</span>
                </Link>
              </li> */}

              <HorizontalLine
                width="100%"
                border="5"
                color="e7e7e7"
                marginTop="0px"
                marginBottom="5px"
              />

              {/* Label: 문자·알림톡 */}
              <li className="menu-title">{this.props.t("문자·알림톡")}</li>

              {/* 가맹점용 다운로드 링크생성 */}
              <li>
                <Link to={AppURLs.ttms.downloadLink} className="">
                  <i className="bx bx-download" />
                  <span>{this.props.t("가맹점용 다운로드 링크생성")}</span>
                </Link>
              </li>

              {/* 메시지 발송 내역 */}
              <li>
                <Link to={AppURLs.ttms.message} className="">
                  <i className="bx bx-message" />
                  <span>{this.props.t("메시지 발송 내역")}</span>
                </Link>
              </li>

              {/* Menu with sub menu */}
              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store" />
                  <span>{this.props.t("Ecommerce")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="#">
                      {this.props.t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {this.props.t("Product Details")}
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* New badge */}
              {/* <li>
                <Link to="/#">
                  <span className="badge rounded-pill bg-success float-end" key="t-new">New</span>
                  <i className="bx bx-briefcase-alt"></i>
                  <span key="t-jobs">{this.props.t("Jobs")}</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="#">{this.props.t("Job List")}</Link></li>
                  <li><Link to="#">{this.props.t("Job Grid")}</Link></li>
                  <li>
                    <Link to="/#" className="has-arrow">{this.props.t("Candidate")}</Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="#">

                          {this.props.t("List")}
                        </Link>
                      </li>
                      <li><Link to="#">{this.props.t("Overview")}</Link></li>
                    </ul>
                  </li>
                </ul>
              </li> */}

              {/* Count badge */}
              {/* <li>
                <Link to="/#">
                  <i className="bx bxs-eraser" />
                  <span className="badge rounded-pill bg-danger float-end">
                    10
                  </span>
                  <span>{this.props.t("Forms")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="#">
                      {this.props.t("Form Elements")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                      {this.props.t("Form Layouts")}
                    </Link>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
