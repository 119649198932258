// import http from "https";
// import https from "https";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { TTMS_API_ENDPOINT } from "configurations";
import AppURLs from "routes/appUrls";
import Swal from "sweetalert2";
import { REFRESH_TOKEN } from "./urlHelper";
import { logger } from 'helpers/debug/logger';

// const httpAgent = new http.Agent({ keepAlive: true });
// const httpsAgent = new https.Agent({ keepAlive: true });

//apply base url for axios
const axiosApi = axios.create({
  baseURL: TTMS_API_ENDPOINT,
//   httpAgent,
//   httpsAgent,
});

axiosApi.interceptors.request.use(config => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: accessToken,
    };
  }
  return config;
});

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest =>
  axios
    .get(REFRESH_TOKEN, {
      // clientId: localStorage.getItem("clientId"),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
      },
      // refreshToken: localStorage.getItem("refreshToken")
    })
    .then(tokenRefreshResponse => {
      localStorage.setItem(
        "accessToken",
        tokenRefreshResponse.data.accessToken
      );
      localStorage.setItem(
        "refreshToken",
        tokenRefreshResponse.data.refreshToken
      );
      failedRequest.response.config.headers["Authorization"] =
        tokenRefreshResponse.data.accessToken;
      return Promise.resolve();
    })
    .catch(() => {
      window.location.href = AppURLs.logout;
    });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosApi, refreshAuthLogic);

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 503) {
      const maintenanceStart = error.response.headers['maintenance-start'];
      const maintenanceEnd = error.response.headers['maintenance-end'];

      // 쿼리 스트링을 통해 유지보수 시간 정보를 전달하면서 page503.html로 리다이렉트
      window.location.href = `/page503.html?start=${encodeURIComponent(maintenanceStart)}&end=${encodeURIComponent(maintenanceEnd)}`;
    }
    return Promise.reject(error);
  }
);


export function get(url, config = {}) {
  return axiosApi
    .get(url, {
      ...config,
    })
    .then(response => response.data);
}

export function getAsync(url, config = {}) {
  return axiosApi
    .get(url, {
      ...config,
    })
    .then(response => response.data)
    .catch(error => {
      console.error(error, "From catch");
      return { status: "failure", message: error };
    });
}

export function post(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      {
        ...data,
      },
      {
        ...config,
      }
    )
    .then(response => response.data);
}

export function postAsync(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      {
        ...data,
      },
      {
        ...config,
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error(error, "From catch");
      return { status: "failure", message: error };
    });
}

export function postFile(url, data, config = {}) {
  return axiosApi
    .post(url, data, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data);
}

export function postFileAsync(url, data, config = {}) {
  return axiosApi
    .post(url, data, {
      ...config,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => response.data)
    .catch(error => {
      console.error(error, "From catch");
      return { status: "failure", message: error };
    });
}

export function put(url, data, config = {}) {
  return axiosApi
    .put(
      url,
      {
        ...data,
      },
      {
        ...config,
      }
    )
    .then(response => response.data)
    // .catch(error => {
    //   console.error(error, "From catch");
    //   return { status: "failure", message: error };
    // });
}

export function putAsync(url, data, config = {}) {
  return axiosApi
    .put(
      url,
      {
        ...data,
      },
      {
        ...config,
      }
    )
    .then(response => response.data)
    .catch(error => {
      console.error(error, "From catch");
      return { status: "failure", message: error };
    });
}

export function putFile(url, data, config = {}) {
  return axiosApi
    .put(url, data, {
      ...config,
    })
    .then(response => response.data);
}

export function putFileAsync(url, data, config = {}) {
  return axiosApi
    .put(url, data, {
      ...config,
    })
    .then(response => response.data)
    .catch(error => {
      logger(error);
      return { status: "failure", message: error };
    });
}

export function del(url, config = {}) {
  return axiosApi
    .delete(url, {
      ...config,
    })
    .then(response => ({ status: "success" }))
    .catch(error => {
      console.log(error);
      console.log(error?.response?.data);
      return error?.response?.data ?? { status: "failure"};
    });
}

export function delAsync(url, config = {}) {
  return axiosApi
    .delete(url, {
      ...config,
    })
    .then(response => response.data)
    .catch(error => {
      logger(error);
      return { status: "failure", message: error };
    });
}

export async function execute(apiPromise, callback, useErrorPopup = true) {
  let rawRes = {};
  await apiPromise
    .then(res => {
      if (res.status === "success") {
        rawRes = { status: res.status, data: res.data };
      } else {
        rawRes = {
          status: res.status,
          message: res.message ?? "API 처리중 문제가 발생하였습니다.",
        };
        if (useErrorPopup) {
          Swal.fire({
            icon: "error",
            title: "API 에러",
            text: res.message ?? "API 처리중 문제가 발생하였습니다.",
            confirmButtonText: "확인",
          }).then(result => {});
        } else {
        }
      }
    })
    .catch(err => {
      logger(err);
      rawRes = {
        status: "failure",
        message: err.message ?? "서버 호출중 문제가 발생하였습니다.",
        data: err.response?.data?.data,
      };
      if (useErrorPopup) {
        Swal.fire({
          icon: "error",
          title: "서버 에러",
          text: err.message ?? "서버 호출중 문제가 발생하였습니다.",
          confirmButtonText: "확인",
        }).then(result => {});
      } else {
      }
    })
    .finally(() => {
      callback(rawRes);
    });
}
