import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppQueryKey } from "common/constants/queryKey";
import BaseApiService from "helpers/api/baseApiService";
import { LoginApiService } from "helpers/api/loginApiService";
import { useCallback } from "react";

const queryKey = [AppQueryKey.user.userInfo];

const useUserInfoQuery = (isLoginSuccess = true) => {
  const queryClient = useQueryClient();

  const userInfoQuery = useQuery(
    queryKey,
    async () => {
      const res = await BaseApiService.getPartnerAdmin();
      if (res.status === "success") {
        const admin = res.data.partner_admin;
        return {
          id: admin.id,
          name: admin.name,
          username: admin.username,
          mobile_no: admin.mobile_no,
          email: admin.username,
          password: admin.password,
          status: admin.status,
          partner_id: admin.partner_id,
          initial_login: admin.initial_login,
          roles: [admin.permission_role],
        };
      }
      return Promise.reject("Fail");
    },
    {
      enabled: isLoginSuccess,
      staleTime: 1000 * 60 * 30, // 30 minutes
      cacheTime: 1000 * 60 * 60, // 60 minutes
    }
  );

  const clearUserInfo = useCallback(() => {
    queryClient.setQueryData(queryKey, {});
    queryClient.invalidateQueries(queryKey); // 쿼리를 무효화함 (stale 상태로 변경)
  }, [queryClient]);

  const updateUserInfo = useMutation({
    mutationFn: async ({ data, callbackFn = () => {} }) => {
      const res = await LoginApiService.changeUserData({
        name: data.name,
        //mobile_no: data.mobile_no,    // mobile number is not editable
      });
      if (res.status === "success") {
        const admin = res.data.partner_admin;
        const oldDataset = queryClient.getQueryData(queryKey);
        const newDataset = {
          ...oldDataset,
          name: admin.name,
          username: admin.username,
          mobile_no: admin.mobile_no,
          email: admin.username,
          password: admin.password,
          status: admin.status,
          partner_id: admin.partner_id,
          initial_login: admin.initial_login,
        };
        queryClient.setQueryData(queryKey, newDataset);
        callbackFn(true, "success");
        return newDataset;
      } else {
        callbackFn(false, res.status);
      }
      return Promise.reject("updateUserInfo failed");
    },
    onSuccess: () => {
      // queryClient.invalidateQueries(queryKey);                   // 쿼리를 무효화함 (stale 상태로 변경)
      // queryClient.resetQueries([AppQueryKey.user.couponList]);   // 캐시된 데이터를 모두 삭제하고 다시 요청한다.
    },
  });

  const updateUserPassword = useMutation({
    mutationFn: async ({ data, callbackFn = () => {} }) => {
      const res = await LoginApiService.changePassword(data);
      if (res.status === "success") {
        const admin = res.data.partner_admin;
        const oldDataset = queryClient.getQueryData(queryKey);
        const newDataset = {
          ...oldDataset,
          name: admin.name,
          username: admin.username,
          mobile_no: admin.mobile_no,
          email: admin.username,
          password: admin.password,
          status: admin.status,
          partner_id: admin.partner_id,
          initial_login: admin.initial_login,
        };
        queryClient.setQueryData(queryKey, newDataset);
        callbackFn(true, "success");
        return newDataset;
      } else {
        callbackFn(false, res.status);
      }
      return Promise.reject("updateUserPassword failed");
    },
  });

  return {
    userInfoQuery,
    updateUserInfo,
    updateUserPassword,
    clearUserInfo,
  };
};

export default useUserInfoQuery;
