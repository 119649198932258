import React, { useCallback, useEffect } from "react";
import { useModalStore } from "../AppState";

const useGlobalModal = () => {
  const showModal = useModalStore(state => state.showModal);
  const closeModal = useModalStore(state => state.closeModal);

  useEffect(() => {
    return closeModal;
  }, [closeModal]);
  return { closeModal, showModal };
};

export const useSimpleModal = () => {
  const showModal = useModalStore(state => state.showModal);
  const closeModal = useModalStore(state => state.closeModal);

  const closeSimpleModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const showSimpleModal = useCallback(
    (title = "", desc = [], onConfirm = () => {}) => {
      const content = (
        <>
          <div className="common-slide-up-container">
            {title && (
              <div className="fs-5">
                <b>{title}</b>
              </div>
            )}
            <div className="fs-6-5 my-4">
              {desc.map((item, i) => (
                <div key={i}>
                  <div className="mb-2">{item}</div>
                </div>
              ))}
            </div>
          </div>
        </>
      );
      showModal({ content, onConfirm });
    },
    [showModal]
  );

  return { showSimpleModal, closeSimpleModal };
};

export default useGlobalModal;
