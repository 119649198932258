import { IS_STAGING } from "../../configurations";

export const logger = (obj, obj2) => {
  if (IS_STAGING) {
    if (obj) {
      if (obj2) {
        console.log(obj, obj2);
      } else {
        console.log(obj);
      }
    } else {
      console.log("Undefined, blank or null");
    }
  }
};

export const loggerMsg = (message, obj = null) => {
  if (process.env.IS_STAGING) {
    if (obj) {
      console.log(message, obj);
    } else {
      console.log(message);
    }
  }
};

export const loggerTrace = message => {
  if (process.env.IS_STAGING) {
    console.trace(message);
  }
};
