import { getAsync } from "../apiHelper";

const BaseApiService = {
  getUserInfo: params => {
    return getAsync("/admin/userinfo", { params });
  },
  getPartnerAdmin: () => {
    return getAsync("/partner-admin", {});
  },
};

export default BaseApiService;
